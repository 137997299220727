<template>
  <ml-dialog
    width="1200px"
    ref="userDialogRef"
    title="值班人员"
    @click-submit="userDialogSubmit"
    @click-close="userDialogClose"
    @before-close="userDialogClose"
  >
    <template #body>
      <div>
        <div>
          <ml-form
            style="width: 1160px"
            inline
            labelWidth="80px"
            :model="userDlgForm"
            ref="userDlgFormRef"
          >
            <!-- 帐号 -->
            <ml-input
              prop="userCodeLike"
              placeholder="请输入账号"
              label="帐号:"
              v-model="userDlgForm.userCodeLike"
            />
            <!-- 用户 -->
            <ml-input
              prop="userNameLike"
              placeholder="请输入用户名"
              label="用户名:"
              v-model="userDlgForm.userNameLike"
              :style="{ marginRight: '40px' }"
            />

            <!-- 搜索按钮 -->
            <ml-button
              submitText="搜索"
              submitIcon="el-icon-search"
              cancelText="重置"
              cancelIcon="el-icon-refresh"
              cancelType="danger"
              @click-cancel="resetSearchDataUser"
              @click-submit="searchUserFn"
            />
          </ml-form>
        </div>
        <div class="assignedPeople-table-box">
          <el-table
            :data="tabberUserData.data"
            :style="{ width: '100%' }"
            :max-height="400"
            @select-all="selectAll"
            @select="select"
            :row-key="row => row.userId"
            ref="userDlgTableRef"
          >
            <el-table-column type="selection" :reserve-selection="true" width="55" />
            <el-table-column align="center" label="账号" prop="userCode" min-width="30%" />
            <el-table-column
              show-overflow-tooltip
              align="center"
              label="用户名"
              prop="userName"
              min-width="15%"
            />
            <el-table-column align="center" label="阿米巴" prop="companyName" min-width="30%" />
            <el-table-column
              show-overflow-tooltip
              align="center"
              label="职位"
              prop="job"
              min-width="20%"
            />
          </el-table>
          <!-- 分页 -->
          <ml-pagination
            :total="tabberUserData.total"
            :page="tabberUserData.page"
            v-model="tabberUserData.page"
            :size="tabberUserData.size"
            @current-change="currentUserChange"
            @size-change="sizeUserChange"
          />
        </div>
      </div>
    </template>
  </ml-dialog>
</template>

<script>
import { reactive, ref } from '@vue/reactivity'
import { onMounted, watch } from '@vue/runtime-core'
import { useStore } from 'vuex'
import { getTabberData, searchParams } from '@/utils'

export default {
  name: 'UserDlg',
  components: {},
  props: {
    modelValue: {
      type: Array,
      default: () => []
    },
    isShow: {
      type: Boolean,
      default: false
    }
  },
  setup(props, centont) {
    const { dispatch } = useStore()
    // dlg dom
    const userDialogRef = ref()
    // 获取现有数据
    const selectData = ref(props.modelValue)
    watch(
      () => props.modelValue,
      newvalue => {
        selectData.value = newvalue
      }
    )
    watch(
      () => props.isShow,
      newvalue => {
        userDialogRef.value.showDialog = newvalue || false
        if (newvalue) {
          getAssignedPeopleData()
        }
      }
    )
    // form
    const userDlgFormRef = ref()
    const userDlgTableRef = ref()
    const userDlgForm = reactive({
      userCodeLike: '',
      userNameLike: ''
    })

    // 表格配置
    const tabberUserData = reactive({
      data: [],
      total: 0,
      page: 1,
      size: 10,
      saveSelectData: [],
      isSubmie: false
    })

    // 获取账户列表
    const getAssignedPeopleData = async (
      params = { pageIndex: tabberUserData.page, pageSize: tabberUserData.size }
    ) => {
      const searchDataParams = searchParams(userDlgForm)
      searchDataParams.userTypes = 'engineer'
      const { data, total } = await getTabberData(
        dispatch,
        'fetchGetAppUsers',
        params,
        searchDataParams
      )
      tabberUserData.data = data || []
      tabberUserData.total = total || 0
      if (selectData.value.length > 0) {
        selectData.value.map(item => {
          userDlgTableRef.value.toggleRowSelection(item, true)
        })
      }
    }
    //分页事件
    watch([() => tabberUserData.page, () => tabberUserData.size], () => {
      getAssignedPeopleData()
    })
    const currentUserChange = page => {
      tabberUserData.page = page
    }
    const sizeUserChange = size => {
      tabberUserData.size = size
    }

    // 重置搜索内容
    const resetSearchDataUser = () => {
      userDlgFormRef.value.CustomFormRef.resetFields()
      tabberUserData.page === 1 ? getAssignedPeopleData() : (tabberUserData.page = 1)
    }
    // 搜索
    const searchUserFn = () => {
      tabberUserData.page === 1 ? getAssignedPeopleData() : (tabberUserData.page = 1)
    }

    // 选中的添加到列表
    const getSelection = selection =>
      selection.map(item => {
        return {
          userId: item.userId,
          userName: item.userName,
          logo: item.logo
        }
      })
    // 选中单个
    const select = selection => {
      selectData.value = getSelection(selection)
    }
    // 选中全部
    const selectAll = selection => {
      selectData.value = selectData.value = getSelection(selection)
    }

    // 提交方法
    const userDialogSubmit = () => {
      centont.emit('update:modelValue', selectData.value)
      userDlgTableRef.value.clearSelection()
      userDlgFormRef.value.CustomFormRef.resetFields()
      centont.emit('update:isShow', false)
    }
    // 关闭
    const userDialogClose = () => {
      userDlgTableRef.value.clearSelection()
      userDlgFormRef.value.CustomFormRef.resetFields()
      centont.emit('update:isShow', false)
    }

    onMounted(() => {})

    return {
      userDlgFormRef,
      userDlgTableRef,
      userDlgForm,
      tabberUserData,
      userDialogRef,
      userDialogSubmit,
      userDialogClose,
      currentUserChange,
      sizeUserChange,
      resetSearchDataUser,
      searchUserFn,
      select,
      selectAll
    }
  }
}
</script>

<style lang="scss">
.user-dlg-body {
}
</style>
