<template>
  <el-table
    :data="nData"
    style="width: 100%"
    border
    :height="tabberHeight(250)"
    :cell-class-name="cellClassHame"
    :span-method="shoWswitch ? arraySpanMethodNull : isLog ? arraySpanMethodLog : arraySpanMethod"
  >
    <el-table-column align="center" prop="classId" label="班次" width="150px">
      <template #default="scope">
        {{ nData.length > 0 ? scope.row.className : '' }}
      </template>
    </el-table-column>
    <el-table-column align="center" props="stateTime" label="时间" width="150px">
      <template #default="scope">
        {{ `${scope.row.startTime} - ${scope.row.endTime}` }}
      </template>
    </el-table-column>
    <el-table-column align="center" prop="taskDesc" :label="isLog ? '提交日志' : '值班工作内容'">
      <template #default="scope">
        <div v-if="isView">
          <div v-if="!shoWswitch && !isLog" :style="{ textAlign: 'left' }">
            {{ scope.row.taskDesc }}
          </div>
          <div v-if="!shoWswitch && isLog" :style="{ textAlign: 'left' }">
            <div
              v-if="
                scope.row.dutyTimeItemUsers &&
                scope.row.dutyTimeItemUsers.length > 0 &&
                showSummarys(scope.row.dutyTimeItemUsers)
              "
            >
              <!-- <el-scrollbar :height="`${scope.row.len * 48}px`"> -->
              <!-- <el-scrollbar height="100%"> -->
              <div v-for="item in scope.row.dutyTimeItemUsers" :key="item" class="log-item">
                <div v-if="item.dutyCalendarSummarys && item.dutyCalendarSummarys.length > 0">
                  <div v-for="s in item.dutyCalendarSummarys" :key="s">
                    <div>{{ s.summary }}</div>
                    <div class="log-item-btn">
                      <div>
                        <el-button
                          type="text"
                          v-if="s.attachVos && s.attachVos.length > 0"
                          @click="downEnclosure(s.sumId)"
                        >
                          下载附件
                        </el-button>
                      </div>
                      <div>{{ s.userName }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- </el-scrollbar> -->
            </div>
            <div v-else :style="{ textAlign: 'center' }">暂无提交日志</div>
          </div>
          <el-switch
            v-if="shoWswitch && !isLog"
            v-model="nIsPaveMonth"
            active-text="是否延用上个月的值班排班情况"
          />
        </div>
        <div v-else>
          <el-input
            v-model="scope.row.taskDesc"
            placeholder="请输入值班内容"
            :input-style="{ border: 0 }"
            :name="scope.row.className"
            @input="onInput"
          ></el-input>
        </div>
      </template>
    </el-table-column>
    <el-table-column
      v-if="isView && !isLog"
      align="center"
      prop="content"
      label="附件"
      width="150px"
    >
      <template #default="scope">
        <el-button
          v-if="isAttachVos(scope.row).length > 0"
          type="text"
          @click="clickFileView(isAttachVos(scope.row))"
        >
          查看附件
        </el-button>
      </template>
    </el-table-column>
  </el-table>
  <!-- 提示框 -->
  <ml-dialog
    ref="isPaveMonthRef"
    content="确定延用上个月的值班排班情况"
    @click-submit="isPaveMonthSubmit"
    @click-close="nIsPaveMonth = false"
  />

  <!-- 查看附件提示框 -->
  <ml-dialog
    width="600px"
    customClass="attachVosView-dialog"
    ref="attachVosViewDialogRef"
    title="附件列表"
    :showBtn="false"
    :showClose="true"
  >
    <template #body>
      <ml-list
        fileName="fileName"
        :showDelete="false"
        :showDown="true"
        :list="attachVosViewDialogData.list"
      />
    </template>
  </ml-dialog>
</template>

<script>
import { tabberHeight, getHoursPeriod, getClassTimeTypeArr } from '@/utils'
import { computed, onMounted, reactive, ref, watch } from '@vue/runtime-core'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { DUTYSUMMARYEXPORT } from '@API'

export default {
  name: 'DutyTable',
  props: {
    modelValue: {
      type: Array,
      default: () => []
    },
    isView: {
      type: Boolean,
      default: true
    },
    isLog: {
      type: Boolean,
      default: false
    },
    isPaveMonth: {
      type: Boolean,
      default: false
    },
    year: {
      type: String
    },
    month: {
      type: String
    },
    cellClassHame: {
      type: String
    }
  },
  setup(props, content) {
    const route = useRoute()
    const { dutyId } = route.query
    const y = computed(() => props.year)
    const m = computed(() => props.month)
    const { commit, dispatch } = useStore()
    const nData = ref([])
    // 是否延用上个月排班
    const nIsPaveMonth = ref(props.isPaveMonth)
    const hArr = getHoursPeriod()
    watch(
      () => props.modelValue,
      newvalue => {
        if (newvalue && newvalue.length > 0) {
          shoWswitch.value = false
          let newData = []
          newvalue.map((item, index) => {
            const { startDayType, startTime, endTime, endDayType } = item
            let sTime = parseInt(startTime)
            let eTime = parseInt(endTime)
            if (startDayType === 0 && startDayType != endDayType) {
              eTime = 24 + eTime
            }
            if (startDayType === 1 && startDayType != endDayType) {
              sTime = 24 + sTime
            }
            const newHArr = getHoursPeriod(sTime, eTime)
            const newTypeArr = getClassTimeTypeArr(sTime, eTime)
            const { dutyTimeHours, dutyTimeItemUsers } = item
            if (dutyTimeHours && dutyTimeHours.length > 0 && newHArr.length > 0) {
              const nLen = [...Array(newHArr.length - 1).keys()]
              if (dutyTimeHours.length >= nLen.length) {
                dutyTimeHours.splice(nLen.length)
              }
              const dutyTimeHoursEndTimes = dutyTimeHours.map(v => parseInt(v.startTime))
              nLen.map(i => {
                if (i + 1 <= nLen.length) {
                  if (dutyTimeHoursEndTimes.includes(parseInt(newHArr[i]))) {
                    const nIndex = dutyTimeHoursEndTimes.indexOf(parseInt(newHArr[i]))
                    newData.push({
                      ...dutyTimeHours[nIndex],
                      dutyTimeItemUsers: dutyTimeItemUsers || [],
                      className: `班次${index + 1}`,
                      dayType: newTypeArr[i],
                      len: newHArr.length - 1
                    })
                  } else {
                    newData.push({
                      className: `班次${index + 1}`,
                      dutyTimeItemUsers: dutyTimeItemUsers || [],
                      endTime: newHArr[i + 1],
                      startTime: newHArr[i],
                      dayType: newTypeArr[i],
                      taskDesc: '',
                      len: newHArr.length - 1
                    })
                  }
                } else {
                  newData.push({
                    className: `班次${index + 1}`,
                    dutyTimeItemUsers: dutyTimeItemUsers || [],
                    endTime: newHArr[i + 1],
                    startTime: newHArr[i],
                    dayType: newTypeArr[i],
                    taskDesc: '',
                    len: newHArr.length - 1
                  })
                }
              })
            } else {
              newHArr.map((v, i) => {
                if (i + 1 < newHArr.length) {
                  newData.push({
                    className: `班次${index + 1}`,
                    dutyTimeItemUsers: dutyTimeItemUsers || [],
                    endTime: newHArr[i + 1],
                    startTime: v,
                    taskDesc: '',
                    len: newHArr.length - 1
                  })
                }
              })
            }
          })
          nData.value = [...newData]
        } else {
          watchData()
        }
      }
    )
    const isPaveMonthRef = ref()
    watch(
      () => nIsPaveMonth.value,
      newvalue => {
        if (newvalue) {
          isPaveMonthRef.value.showDialog = true
        }
      }
    )

    watch(
      () => props.isPaveMonth,
      newvalue => {
        nIsPaveMonth.value = newvalue
      }
    )

    const isPaveMonthSubmit = async () => {
      let isShow = false
      const tDate = new Date()
      const year = y.value || tDate.getFullYear()
      const month = m.value || tDate.getMonth() + 1
      const data = await dispatch('fetchLastMonthCalendarSave', { dutyId, year, month })
      if (data && data.code === 200) {
        isShow = true
        commit('setError', {
          status: true,
          title: data.message,
          message: '正在获取新的数据...',
          type: 'success'
        })
        isPaveMonthRef.value.showDialog = false
      } else {
        isShow = false
      }
      nIsPaveMonth.value = isShow
      content.emit('update:isPaveMonth', isShow)
    }

    const shoWswitch = ref(true)
    const watchData = () => {
      shoWswitch.value = true
      nData.value = hArr.map((item, index) => {
        return {
          className: '',
          startTime: item,
          endTime: index < 23 ? hArr[index + 1] : hArr[0],
          content: ''
        }
      })
    }

    const onInput = () => {
      const nModelValue = props.modelValue
      const dutyTimeHoursArr = []
      nData.value.map(item => {
        const index = Number(item.className.substring(2)) - 1
        if (!dutyTimeHoursArr[index]) {
          dutyTimeHoursArr[index] = []
        }
        dutyTimeHoursArr[index].push({ ...item })
      })
      nModelValue.map((item, index) => {
        item.dutyTimeHours = dutyTimeHoursArr[index]
      })
      content.emit('update:modelValue', [...nModelValue])
    }
    // 合并行 data = []
    const arraySpanMethodNull = ({ rowIndex, columnIndex }) => {
      if ([0, 2].includes(columnIndex)) {
        if (rowIndex % 24 === 0) {
          if ([2].includes(columnIndex)) {
            return [24, 2]
          } else {
            return {
              rowspan: 24,
              colspan: 1
            }
          }
        } else {
          return {
            rowspan: 0,
            colspan: 0
          }
        }
      }
    }
    // 合并行 data = [...]
    const arraySpanMethod = ({ row, rowIndex, columnIndex }) => {
      if (columnIndex === 0) {
        if (rowIndex === 0 || row.className != nData.value[rowIndex - 1].className) {
          let rowSpan = 0
          nData.value.map(item => {
            if (item.className === row.className) {
              rowSpan++
            }
          })
          return [rowSpan, 1]
        } else {
          return [0, 0]
        }
      }
    }
    // 合并行 data = [...]
    const arraySpanMethodLog = ({ row, rowIndex, columnIndex }) => {
      if (columnIndex === 0 || columnIndex === 2) {
        if (rowIndex === 0 || row.className != nData.value[rowIndex - 1].className) {
          let rowSpan = 0
          nData.value.map(item => {
            if (item.className === row.className) {
              rowSpan++
            }
          })
          return [rowSpan, 1]
        } else {
          return [0, 0]
        }
      }
    }

    // 查看日志是否有附件
    const isAttachVos = row => {
      let attachVos = []
      if (row.dutyTimeHourRecords && row.dutyTimeHourRecords.length > 0) {
        row.dutyTimeHourRecords.map(item => {
          if (item.attachVos && item.attachVos.length) {
            attachVos = [...attachVos, ...item.attachVos]
          }
        })
      }
      return attachVos
    }

    // 查看附件列表
    const attachVosViewDialogRef = ref()
    const attachVosViewDialogData = reactive({
      list: []
    })
    // 查看附件
    const clickFileView = list => {
      attachVosViewDialogData.list = list || []
      attachVosViewDialogRef.value.showDialog = true
    }

    // 下载附件
    const downEnclosure = sumId => {
      if (sumId) {
        location.href = `${DUTYSUMMARYEXPORT}?sumId=${sumId}`
      }
    }
    // 校验是否有日志总结
    const showSummarys = (dutyTimeItemUsers = []) => {
      let isShowSummarys = false
      dutyTimeItemUsers.map(item => {
        if (item.dutyCalendarSummarys && item.dutyCalendarSummarys.length > 0) {
          isShowSummarys = true
        }
      })
      return isShowSummarys
    }

    onMounted(() => {
      watchData()
    })
    return {
      tabberHeight,
      nData,
      nIsPaveMonth,
      arraySpanMethodNull,
      arraySpanMethod,
      arraySpanMethodLog,
      onInput,
      shoWswitch,
      isPaveMonthRef,
      isPaveMonthSubmit,
      isAttachVos,
      clickFileView,
      downEnclosure,
      attachVosViewDialogRef,
      attachVosViewDialogData,
      showSummarys
    }
  }
}
</script>

<style lang="scss">
.log-item {
  padding: 20px;
  &-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
  }
}
</style>
