<template>
  <!-- <el-form inline :model="nData"> -->
  <div class="class-item" v-for="(item, index) in nData" :key="item" :style="style">
    <!-- 详情查看 -->
    <div v-if="isView" class="class-item-title class-item-title-v">
      <span class="class-item-name">班次{{ index + 1 }}</span>
      {{ item.startTime }}-{{ item.endTime }}
    </div>
    <!-- 详情编辑 -->
    <div v-else class="class-item-title">
      <span class="class-item-name">班次{{ index + 1 }}</span>
      <div class="class-item-select">
        <!-- <el-form-item prop="startDayType" :name="`name_${index}`"> -->
        <el-select
          size="mini"
          v-model="item.startDayType"
          style="width: 70px"
          @change="changeSelect"
        >
          <el-option
            v-for="o in classOption"
            :key="`start_${index}_${o.value}`"
            :label="o.label"
            :value="o.value"
          />
        </el-select>
        <!-- </el-form-item>
          <el-form-item prop="startTime" :name="`name_${index}`"> -->
        <el-select size="mini" v-model="item.startTime" style="width: 80px" @change="changeSelect">
          <el-option
            v-for="(o, i) in getHoursPeriod()"
            :key="`start_${index}_${i}`"
            :label="o"
            :value="o"
          />
        </el-select>
        <!-- </el-form-item>
          <el-form-item prop="endDayType" :name="`name_${index}`"> -->
        <el-select size="mini" v-model="item.endDayType" style="width: 70px" @change="changeSelect">
          <el-option
            v-for="o in classOption"
            :key="`start_${index}_${o.value}`"
            :label="o.label"
            :value="o.value"
          />
        </el-select>
        <!-- </el-form-item>
          <el-form-item prop="endTime" :name="`name_${index}`"> -->
        <el-select size="mini" v-model="item.endTime" style="width: 80px" @change="changeSelect">
          <el-option
            v-for="(o, i) in getHoursPeriod()"
            :key="`end_${index}_${i}`"
            :label="o"
            :value="o"
          />
        </el-select>
        <!-- </el-form-item> -->
      </div>
      <div class="class-item-btn">
        <el-button
          v-if="nData.length > 1"
          type="danger"
          size="mini"
          icon="el-icon-delete"
          circle
          @click="removeClass(index)"
        />
        <el-button
          v-if="index === nData.length - 1"
          type="primary"
          size="mini"
          icon="el-icon-plus"
          circle
          @click="addClass"
        />
      </div>
    </div>
    <user-item :isLog="isLog" :data="item.dutyTimeItemUsers" @click-log="clickLog" />
    <div :style="{ margin: '0 0 10px 20px' }" v-if="!isView">
      <el-button type="primary" size="mini" icon="el-icon-plus" @click="addDutyMan(index)">
        值班人
      </el-button>
    </div>
  </div>
  <!-- </el-form> -->
  <!-- 是否删除班次 -->
  <ml-dialog
    ref="deleteClassDlgRef"
    content="确定删除此班次吗？"
    @click-submit="deleteClassDlgSub"
  />
  <!-- 指派人Dlg -->
  <user-dlg v-model="selectData" v-model:isShow="isShowUserDlg" />
</template>

<script>
import { tabberHeight, getHoursPeriod } from '@/utils'
import { onMounted, reactive, ref, watch } from '@vue/runtime-core'
import UserItem from './userItem.vue'
import UserDlg from './userDlg.vue'

export default {
  name: 'ClassITem',
  emits: ['on-add-class', 'update:modelValue'],
  components: {
    UserItem,
    UserDlg
  },
  props: {
    modelValue: {
      type: Array,
      default: () => []
    },
    style: Object,
    isView: {
      type: Boolean,
      default: true
    },
    isLog: {
      type: Boolean,
      default: false
    }
  },
  setup(props, content) {
    const nData = ref(props.modelValue)
    watch(
      () => props.modelValue,
      newvalue => {
        nData.value = [...newvalue]
      }
    )
    const changeSelect = () => {
      content.emit('update:modelValue', nData.value)
    }
    // 时间类型
    const classOption = ref([
      { label: '当天', value: 0 },
      { label: '明天', value: 1 }
    ])

    // 添加班次
    const addClass = () => {
      content.emit('on-add-class')
    }

    // 删除班次
    const deleteClassDlgRef = ref()
    const deleteClassDlgData = reactive({
      index: -1
    })
    const removeClass = index => {
      deleteClassDlgData.index = index
      deleteClassDlgRef.value.showDialog = true
    }
    const deleteClassDlgSub = () => {
      nData.value.splice(deleteClassDlgData.index, 1)
      deleteClassDlgRef.value.showDialog = false
      content.emit('update:modelValue', nData.value)
    }

    // 添加值班人
    const userDlgRef = ref()
    const isShowUserDlg = ref(false)
    const selectData = ref([])
    const selectIndex = ref(-1)
    const addDutyMan = index => {
      selectIndex.value = index
      selectData.value = nData.value[index].dutyTimeItemUsers || []
      isShowUserDlg.value = true
    }

    watch(
      () => selectData.value,
      newvalue => {
        if (selectIndex.value !== -1) {
          nData.value[selectIndex.value] = {
            ...nData.value[selectIndex.value],
            dutyTimeItemUsers: newvalue
          }
          content.emit('update:modelValue', nData.value)
        }
      }
    )

    const clickLog = item => {
      content.emit('click-log', item)
    }

    onMounted(() => {})
    return {
      classOption,
      tabberHeight,
      getHoursPeriod,
      nData,
      addClass,
      removeClass,
      deleteClassDlgRef,
      deleteClassDlgSub,
      addDutyMan,
      changeSelect,
      userDlgRef,
      isShowUserDlg,
      selectData,
      clickLog
    }
  }
}
</script>

<style lang="scss">
.class-item {
  margin: 10px 0;
  margin: 10px;
  &-title {
    display: flex;
    padding-left: 10px;
    border-left: 4px solid $--color-primary;
    // font-weight: bold;
    font-size: 16px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    &-v {
      justify-content: flex-start;
    }
  }
  &-name {
    width: 50px;
    margin-right: 10px;
  }
  &-select {
    display: flex;
    flex: 1;
    margin-right: 10px;
  }
  &-btn {
    display: flex;
    align-items: center;
    width: 70px;
  }
  .el-button--mini,
  .el-button--mini.is-round {
    padding: 8px;
  }
}
</style>
