<template>
  <div class="user-item" v-for="item in nData" :key="item" :style="style">
    <div>
      <el-avatar :size="30" :src="setLogo(item.logo)" />
      <span :style="{ marginLeft: '10px' }">{{ item.userName }}</span>
    </div>
    <el-button
      v-if="isLog && item.dutyCalendarSummarys && item.dutyCalendarSummarys.length <= 0"
      type="primary"
      size="mini"
      @click="submitLog(item)"
      >提交日志</el-button
    >
  </div>
</template>

<script>
import { onMounted, ref } from '@vue/runtime-core'
import { BASEURL, FILEPREVIEWURL } from '@API'

export default {
  name: 'UserItem',
  props: {
    data: {
      type: Array,
      default: () => []
    },
    isLog: {
      type: Boolean,
      default: false
    },
    style: Object
  },
  setup(props, content) {
    const nData = ref(props.data)
    const defaultLogo = require('@image/avatar-default.png')
    const setLogo = logo => {
      if (logo) {
        return `${BASEURL}${FILEPREVIEWURL}/${logo}`
      } else {
        return defaultLogo
      }
    }
    const submitLog = item => {
      content.emit('click-log', item)
    }
    onMounted(() => {})
    return { nData, setLogo, submitLog }
  }
}
</script>

<style lang="scss">
.user-item {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-radius: 20px;
  margin-bottom: 10px;
  justify-content: space-between;
  &:hover {
    background-color: #eff6fe;
  }
}
</style>
